import React from "react";
import Navbar from "components/Navbar/navbar";
import ProjectmanagementIntro from "components/Intro2/ProjectmanagementIntro";
import Services from "components/Services/services";
import Video2 from "components/Video2/video2";
import CallToAction from "components/Call-to-action/call-to-action";
import Footer from "components/Footer/footer";
import Portfolio from "components/Portfolio/portfolio";
import FullTestimonials from "components/Full-testimonials/full-testimonials";
import Team2 from "components/Team2/team2";
import Blogs2 from "components/blogs/Blogs2/blogs2";
import LightTheme from "layouts/Light";
import Iotadvisory2 from "components/Services/iotadvisory2";
import Iotadvisory3 from "components/Services/Iotadvisory3";

const ProjectManagement = () => {
  const navbarRef = React.useRef(null);
  const logoRef = React.useRef(null);

  React.useEffect(() => {
    var navbar = navbarRef.current;

    if (window.pageYOffset > 300) {
      navbar.classList.add("nav-scroll");
    } else {
      navbar.classList.remove("nav-scroll");
    }

    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        navbar.classList.add("nav-scroll");
      } else {
        navbar.classList.remove("nav-scroll");
      }
    });
  }, [navbarRef]);

  return (
    <LightTheme>
      <Navbar nr={navbarRef} lr={logoRef} theme="themeL" />
      <ProjectmanagementIntro />
      <Services style="3item" />
      <Iotadvisory2 style="4item" />
      <Iotadvisory3 style="3item" />
      {/* <Video2 />
      <Portfolio grid={3} filterPosition="center" />
      <FullTestimonials noPadding />
      <Team2 />
      <Blogs2 /> */}
      <CallToAction />
      <Footer />
    </LightTheme>
  );
};

export const Head = () => {
  return (
    <>
      <title>Connectified - Project Management</title>
    </>
  )
}

export default ProjectManagement;
